import React from 'react'
import {
    Button,
    Dialog,
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core'
import { ProductService } from './productService'
import { StringService } from '../utils/stringService'
import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    HeaderTableCell,
    OverflowXDiv,
    PrimaryButton,
    FlexBaselineDiv,
} from '../components/components'
import { DateTimeService } from '../utils/dateTimeService'
import { InspectionTypeService } from '../inspectionType/inspectionTypeService'
import { CategoryService } from '../category/categoryService'
import SearchIcon from '../equipment/images/search_list_ico.png'
import { ProductFormModalButton } from './productFormModalButton'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { SortHeaderCell } from '../components/sortHeaderCell'
import { withSnackbar } from 'notistack'
import NoImage from './images/mobcheck-gray.png'
import NoImageIn from './images/no-image.png'
import { CollectionViewUpdater } from '../utils/collectionViewUpdater'
import Spinner from 'react-spinner-material'
import { LoggedUserService } from '../loggedUser/loggedUserService'
import { RoleConstant } from '../../app/roleConstant'
import { FilterDramaTwoTone } from '@material-ui/icons'
import { FilterDatePicker } from '../components/filterDatePicker';

class ProductsPage extends React.Component {
    constructor(props) {
        super(props)

        this.collectionUpdater = CollectionViewUpdater.ofProducts(this)

        this.state = {
            isLoading: false,
            filterQuery: '',
            filterCategoryId: '',
            filterInspectionTypeId: '',
            filterDateFrom: null, 
            filterDateUpTo: null, 
            filterRechargeDateFrom: null, 
            filterRechargeDateUpTo: null, 
            filterWeightDateFrom: null, 
            filterWeightDateUpTo: null,
            isImageDialogOpen: false,
            imageProductId: null,
            inspectionTypes: [],
            categories: [],
            collection: this.collectionUpdater.collection,
            loggedUser: {}
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })

        ProductService.list().then(items => {
            this.collectionUpdater.initialData(items)
            this.setState({ isLoading: false })
        })

        InspectionTypeService.list().then(inspectionTypes => this.setState({ inspectionTypes }))
        CategoryService.list().then(categories => this.setState({ categories }))

        LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })
    }

    closeImageDialog = () => this.setState({ isImageDialogOpen: false })

    notify = message => () => this.props.enqueueSnackbar(message)

    handleCreateSubmit = (productData, photo, maintenanceParts) => ProductService.create(productData, photo, maintenanceParts)
        .then(this.collectionUpdater.createItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.created_product))).then(this.componentDidMount())
    handleUpdateSubmit = (productId, productData, photo, maintenanceParts) => ProductService.update(productId, productData, photo, maintenanceParts)
        .then(this.collectionUpdater.updateItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.updated_product, productData.name)))

    buildList = () => {
        const { isAdmin, currentLanguage } = this.props
        const { isLoading, collection, categories, inspectionTypes, loggedUser, filterCategoryId } = this.state

        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={6}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems;

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={6}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        

        return (
            items.map(product => (
                <TableRow key={product.id}>
                    <BodyTableCell align="center">
                    <Button onClick={() => this.setState({ isImageDialogOpen: true, imageProductId: product.id })}>
                          {product.id && product.updateDate ? (
                            <img
                                style={{ height: '2rem' }}
                                alt="preview"
                                src={`/web/products/${product.id}/image?update=${product.updateDate}`}
                                onError={(e) => e.target.src = NoImageIn} 
                            />
                            ) : (
                            <span>Sem imagem</span>
                            )}
                    </Button>
                    </BodyTableCell>
                    <BodyTableCell>{product.sku}</BodyTableCell>
                    <BodyTableCell>{product.name}</BodyTableCell>
                    <BodyTableCell> {product.isDvExclusive ? currentLanguage.yes : currentLanguage.no}</BodyTableCell>
                    <BodyTableCell> {DateTimeService.formatDate(product.updateDate)}</BodyTableCell>
                    <BodyTableCell>
                        <ProductFormModalButton
                            currentLanguage={currentLanguage}
                            submitLabel={currentLanguage.update_product}
                            showIsDvExclusive={loggedUser.currentRole === RoleConstant.MOBCHECK_ADMIN}
                            isReadOnly={!isAdmin && product.isDvExclusive}
                            productId={product.id}
                            productData={{
                                inspectionTypeId: product.inspectionTypeId,
                                name: product.name,
                                sku: product.sku,
                                shortDescription: product.shortDescription,
                                isDvExclusive: product.isDvExclusive,
                                isDueDate: product.isDueDate,
                                enabled: product.enabled,
                                inspectionSchedule: product.inspectionSchedule,
                                daysNotificationDueDate: product.daysNotificationDueDate,
                                daysNotificationNextInspectionDate: product.daysNotificationNextInspectionDate,
                                manufacturingNumber: product.manufacturingNumber,
                                manufacturer: product.manufacturer
                            }}
                            categories={categories}
                            inspectionTypes={inspectionTypes}
                            onSubmit={(productData, photo, maintenanceParts) => this.handleUpdateSubmit(product.id, productData, photo, maintenanceParts)}
                            ModalButton={({ onClick }) => (
                                <IconButton onClick={onClick}><img src={SearchIcon} alt="search" /></IconButton>
                            )}
                            photoUrl={`/web/products/${product.id}/image?update=${product.updateDate}`}
                        />
                    </BodyTableCell>
                </TableRow>
            ))
        );
    };

    render() {
        const { currentLanguage } = this.props
        const { isImageDialogOpen, imageProductId, collection, categories, inspectionTypes, filterQuery, loggedUser, filterCategoryId, filterInspectionTypeId, filterDateFrom, filterDateUpTo, filterRechargeDateFrom, filterRechargeDateUpTo, filterWeightDateFrom, filterWeightDateUpTo} = this.state
        const categoryInspectionTypes = inspectionTypes.filter(inspectionType => inspectionType.categoryId === filterCategoryId);
        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater



        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                        <FormControl margin="normal">
                            <TextField
                                label={currentLanguage.search}
                                helperText={currentLanguage.sku + ", " + currentLanguage.name}
                                value={filterQuery}
                                style={{ marginBottom: '1rem' }}
                                onChange={event => handleFilterChange({ filterQuery: event.target.value })}
                            />
                        </FormControl>
                        <FormControl margin="normal" style={{width: '25ch'}}>
                            <InputLabel htmlFor="categoryId-select" id= 'categoryId-select"'>{currentLanguage.category}</InputLabel>
                            <Select value={filterCategoryId} labelId="categoryId-select" autoWidth={true} onChange={event => handleFilterChange({ filterCategoryId: event.target.value, filterInspectionTypeId: '' })} inputProps={{ id: 'categoryId-select' }}>
                                <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                {categories.map(category => (
                                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl margin="normal"  style={{width: '25ch'}}>
                            <InputLabel htmlFor="inspectionTypeId-select">{currentLanguage.subcategories}</InputLabel>
                            <Select value={filterInspectionTypeId} onChange={event => handleFilterChange({ filterInspectionTypeId: event.target.value })} inputProps={{ id: 'inspectionTypeId-select' }}>
                                <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                {categoryInspectionTypes.map(inspectionType => (
                                    <MenuItem key={inspectionType.id} value={inspectionType.id}>{inspectionType.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                       
                        <ProductFormModalButton
                            currentLanguage={currentLanguage}
                            submitLabel={currentLanguage.add_product}
                            showIsDvExclusive={loggedUser.currentRole === RoleConstant.MOBCHECK_ADMIN}
                            isReadOnly={false}
                            productId={null}
                            productData={{
                                inspectionTypeId: '',
                                name: '',
                                sku: '',
                                shortDescription: '',
                                isDvExclusive: false,
                                isDueDate: false,
                                enabled: false,
                                inspectionSchedule: '',
                                daysNotificationDueDate: 0,
                                daysNotificationNextInspectionDate: 0,
                                manufacturingNumber: '', 
                                manufacturer: ''
                            }}

                            categories={categories}
                            inspectionTypes={inspectionTypes}
                            onSubmit={(productData, photo, maintenanceParts) => this.handleCreateSubmit(productData, photo, maintenanceParts)}
                            ModalButton={({ onClick }) => (
                                <PrimaryButton onClick={onClick} style={{ alignSelf: 'center' }}>
                                    {currentLanguage.add_product}
                                </PrimaryButton>
                            )}
                            photoUrl={NoImage}
                        />
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <Dialog open={isImageDialogOpen} onClose={this.closeImageDialog}>
                    <img
                    src={`/web/products/${imageProductId}/image`}
                    alt="product"
                    onError={(e) => e.target.src = NoImageIn} 
                    />
                    <Button onClick={this.closeImageDialog}>{currentLanguage.close}</Button>
                </Dialog>

                <OverflowXDiv>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell align="center">{currentLanguage.image}</HeaderTableCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="sku">{currentLanguage.sku}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="name">{currentLanguage.name}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="isDvExclusive">{currentLanguage.exclusive}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="updateDate">{currentLanguage.last_update}</SortHeaderCell>
                                <HeaderTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildList()}
                        </TableBody>
                        <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={6} />
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        )
    }
}

export default withSnackbar(ProductsPage)
