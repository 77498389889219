import { Filter } from '@material-ui/icons';
import { DefaultCollection } from '../collection/defaultCollection';
import { FilterService } from './filterService';

export class CollectionViewUpdater {

    constructor(view, buildPredicates, identifierFieldKey, collection) {
        this.view = view;
        this.buildPredicates = buildPredicates;
        this.identifierFieldKey = identifierFieldKey;
        this.collection = collection;
    }

    static ofEquipments(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery, filterStatus, filterCategoryId, filterCompanyId, filterInspectionTypeId, filterProductId, filterEnabledTagsOnly, filterCreateFrom, filterCreateUpTo, filterLastInspectionFrom, filterLastInspectionUpTo, filterLastInspectionExpirationStatus, filterDueDateFrom, filterDueDateUpTo, filterDueDateExpirationStatus } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['product.sku', 'product.name', 'extraCode', 'extraLabel', 'lastInspection.userName', 'lastInspection.userEmail']),
                    FilterService.isSameString('status', filterStatus),
                    FilterService.isStrictEqual('enabledTag', filterEnabledTagsOnly),
                    FilterService.isStrictEqual('product.categoryId', filterCategoryId),
                    FilterService.isStrictEqual('companyId', filterCompanyId),
                    FilterService.isStrictEqual('product.inspectionTypeId', filterInspectionTypeId),
                    FilterService.isStrictEqual('product.id', filterProductId),
                    FilterService.isFromValid('createdAt', filterCreateFrom),
                    FilterService.isUpToValid('createdAt', filterCreateUpTo),
                    FilterService.isFromValid('lastInspection.createdAt', filterLastInspectionFrom),
                    FilterService.isUpToValid('lastInspection.createdAt', filterLastInspectionUpTo),
                    FilterService.isEquivalentExpirationStatus('lastInspectionExpirationStatus', filterLastInspectionExpirationStatus),
                    FilterService.isFromValid('dueDate', filterDueDateFrom),
                    FilterService.isUpToValid('dueDate', filterDueDateUpTo),
                    FilterService.isEquivalentExpirationStatus('dueDateExpirationStatus', filterDueDateExpirationStatus)
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofMaintenance(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery, filterStatus, filterCategoryId, filterInspectionTypeId, filterProductId, filterEnabledTagsOnly, filterCreateFrom, filterCreateUpTo, filterLastInspectionFrom, filterLastInspectionUpTo, filterLastInspectionExpirationStatus, filterDueDateFrom, filterDueDateUpTo, filterDueDateExpirationStatus } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['product.sku', 'product.name', 'extraCode', 'extraLabel', 'lastMaintenance.userName', 'lastMaintenance.userEmail']),
                    FilterService.isSameString('status', filterStatus),
                    FilterService.isStrictEqual('enabledTag', filterEnabledTagsOnly),
                    FilterService.isStrictEqual('product.categoryId', filterCategoryId),
                    FilterService.isStrictEqual('product.inspectionTypeId', filterInspectionTypeId),
                    FilterService.isStrictEqual('product.id', filterProductId),
                    FilterService.isFromValid('createdAt', filterCreateFrom),
                    FilterService.isUpToValid('createdAt', filterCreateUpTo),
                    FilterService.isFromValid('lastInspection.createdAt', filterLastInspectionFrom),
                    FilterService.isUpToValid('lastInspection.createdAt', filterLastInspectionUpTo),
                    FilterService.isEquivalentExpirationStatus('lastInspectionExpirationStatus', filterLastInspectionExpirationStatus),
                    FilterService.isFromValid('dueDate', filterDueDateFrom),
                    FilterService.isUpToValid('dueDate', filterDueDateUpTo),
                    FilterService.isEquivalentExpirationStatus('dueDateExpirationStatus', filterDueDateExpirationStatus)
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofUsers(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery, filterStatus } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['name', 'email', 'occupation', 'phone', 'companyName', 'companyGroup']),
                    FilterService.isSameString('status', filterStatus)
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofCompanyUsers(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery, filterStatus } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['name', 'email', 'occupation', 'phone', 'companyName']),
                    FilterService.isSameString('status', filterStatus)
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofCompanyGroups(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['name']),
                ];

            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofCategories(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery, filterEnabled } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['name']),
                    FilterService.isSameString('enabled', filterEnabled)
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofInspectionTypes(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['name', 'categoryName']),
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    static ofProducts(view) {
        return new CollectionViewUpdater(view,
            (items, filterValues) => {
                const { filterQuery, filterCategoryId, filterInspectionTypeId, filterDateFrom, filterDateUpTo, filterRechargeDateFrom, filterRechargeDateUpTo, filterWeightDateFrom, filterWeightDateUpTo } = filterValues;

                return [
                    FilterService.containsQuery(filterQuery, items, ['sku', 'name']),
                    FilterService.isFromValid('retestDate', filterDateFrom),
                    FilterService.isUpToValid('retestDate', filterDateUpTo),
                    FilterService.isFromValid('rechargeDate', filterRechargeDateFrom),
                    FilterService.isUpToValid('rechargeDate', filterRechargeDateUpTo),
                    FilterService.isFromValid('weightDate', filterWeightDateFrom),
                    FilterService.isUpToValid('weightDate', filterWeightDateUpTo),
                    FilterService.isStrictEqual('categoryId', filterCategoryId),
                    FilterService.isStrictEqual('inspectionTypeId', filterInspectionTypeId),
                ];
            },
            'id',
            DefaultCollection.ofItemsAndPredicates([], [])
        );
    }

    initialData = items => this.view.setState(state => ({ collection: DefaultCollection.ofItemsAndPredicates(items, this.buildPredicates(items, state)) }));

    updateCollection = updater => this.view.setState(state => ({ collection: updater(state.collection, state) }));

    handleFilterChange = newFilterState => {
        this.view.setState(newFilterState);

        return this.updateCollection((collection, state) => DefaultCollection.withItems(collection, collection.items, this.buildPredicates(collection.items, state)));
    };

    handleSort = field => this.updateCollection(collection => {
        return DefaultCollection.withNextSorter(collection, field)
    });

    updateWithCreatedItem = createdItem => (collection, state) => DefaultCollection.withCreatedItem(collection, this.identifierFieldKey, createdItem, this.buildPredicates(collection.items, state));
    updateWithUpdatedItem = updatedItem => (collection, state) => DefaultCollection.withUpdatedItem(collection, this.identifierFieldKey, updatedItem, this.buildPredicates(collection.items, state));
    updateWithoutDeletedItem = deletedItem => (collection, state) => DefaultCollection.withoutDeletedItem(collection, this.identifierFieldKey, deletedItem, this.buildPredicates(collection.items, state));

    createItem = createdItem => this.updateCollection(this.updateWithCreatedItem(createdItem));
    updateItem = updatedItem => this.updateCollection(this.updateWithUpdatedItem(updatedItem));
    deleteItem = deletedItem => this.updateCollection(this.updateWithoutDeletedItem(deletedItem));

}
